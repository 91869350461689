import $ from 'jquery';

class GlobalNavigation {
    constructor() {
        this.previousScroll = 0;
        this.hasScrolled = false;
        this.delta = 5;
        this.header = $('#header');
        this.headerPreviousHeight = 0;
        this.menuContainer = $('.menu-container');
        this.toggleMenu = false;
    }

    init() {
        this.bindEvents();
        this.toggleMobileMenu();
        this.onResizeHandler();
    }

    bindEvents() {            
        
    }

    onScrollHide() {
        window.onscroll = function(event) {
            let scroll = $(window).scrollTop();

            if (Math.abs(this.previousScroll - scroll) <= this.delta) {
                return;
            }

            if(scroll < 50) 
            {
                this.header.removeClass('header-truncated');
                this.header.addClass('header-primary');
                this.header.removeAttr('style');
            }
            if(scroll > 50) 
            {
                this.animateTruncatedNavigation(scroll);                
                this.header.addClass('header-truncated');
                this.header.removeClass('header-primary');
            }
        }.bind(this);
    }

    onResizeHandler() {
        window.onresize = () => {
            let windowWidth = $( window ).width();
            
            if (windowWidth > 1200) 
            {
                this.header.css('height', '');
                this.header.removeClass('header-menu-open');
                $('.sub-menu').removeAttr('style');
                $('body, html').removeClass('navbar-open');
                this.toggleMenu = false;
            }
        }
    }

    animateTruncatedNavigation(scroll) {
        let topPosition = $('body').hasClass('admin-bar') ? $('#wpadminbar').height() : '0px';
        if (scroll < this.previousScroll) 
        {            
            this.header.css('top', topPosition);
        }

        if (scroll > this.previousScroll)
        {
            this.header.css('top', '-100px');

        }
        this.previousScroll = scroll;
    }

    toggleMobileMenu() {        
        $('.header-menu-toggle').on('click', () => {
            if (!this.toggleMenu) {
                this.header.addClass('header-menu-open');
                $('.search-container').show();
                $('#searchform').removeClass('slide-out');
                $('body, html').addClass('navbar-open');
                this.toggleMenu = true;
                return;
            }
            this.header.css('height', '');
            this.header.removeClass('header-menu-open');
            $('body, html').removeClass('navbar-open');
            this.toggleMenu = false;
        });

        $('#menu-header li').on('click', (e) => {
            let subMenu = $(e.currentTarget).find('.sub-menu');
            let subMenuIcon = $(e.currentTarget).find('i');
            let windowWidth = $( window ).width();
            if (windowWidth > 1024) {
                return;
            }
            if (subMenu.hasClass('sub-menu-open')) {
                subMenu.removeClass('sub-menu-open').slideUp();
                subMenuIcon.removeClass('fa-rotate-180');
                return;
            }
            subMenu.addClass('sub-menu-open').slideDown();
            subMenuIcon.addClass('fa-rotate-180');
            
        });
    }
}

export default GlobalNavigation;